.announcement {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 25px;
  background: #1976d2;
  color: #ffffff;
  height: 45px;
  font-weight: 500;
}
.cursor-pointer {
  cursor: pointer;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.announcement-dialogbox {
  .MuiPaper-root {
    max-width: 1000px;
    width: 100%;
  }
  .MuiDialogTitle-root {
    line-height: 1.9;
    padding: 5px 10px 5px 20px;
  }
  .MuiDialogContent-root {
    overflow: hidden;
  }
}