/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import "../../assets/styles/variable.scss";
@import "../../assets/styles/global.scss";

.dialogue-modal {
    position: relative;
}

.term-comparision-box {
    position: sticky;
    z-index: 99;
    top: 0px;
    background-color: white;
    border-bottom: 1px solid lightgray;
}

.rolesPopupWrapper {
    .titleText {
        font-family: $Roboto-light  !important;
        font-size: 20px;
    }
  

    .saveButton {
        font-family: $Roboto-regular  !important;
        font-size: 17px;
    }

    .loader {

        height: 40px;

        display: flex !important;
        align-items: center !important;
        justify-content: center !important;

        .CircularProgress {
            padding-right: 20px;
        }
    }

    .rolesWrapper{
        padding:15px 8px;
    }

}