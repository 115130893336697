/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import'../../assets/styles/variable.scss';
.homeWrapper {

    width: 100vw;
    height: 100vh;
    background-color: $Background-Color-SideBar;
    .outletWrapper {
        width: 100vw;
        height: calc(100vh - 50px);
        overflow: auto;
        background-color: #f7f8fc;        
    }
}