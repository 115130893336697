/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import '../../assets/styles/variable.scss';
@import '../../assets/styles/global.scss';

.agreementHomeWrapper {
    display: flex;
    height: calc(100vh - 110px);

    .projectGridtableClass {
        width: calc(100vw - 265px);
        @media (max-width:576px) {
            width: 100%;            
        }
    }
    .ptojectSidebarStyle{
        width: 265px;
        @media (max-width:576px) {
            width: 100%;      
            position: absolute;      
        }
    }
    .toogleButton{
        width: 100%;
        @media (max-width:576px) {          
            width: 0px;
        }
    }
    
    .chipsADD {
        display: flex;
        overflow: auto;
        -ms-overflow-style: none;
        scrollbar-width: none;
        overflow-x: auto;
        width: 85%;
        @media (max-width:576px) {
            width: 100%;
            padding-left: 2px;
        }

        .MuiChip-label{
            padding-left: 6px;
        }
    }

    .projectCountMain{
        width: 15%;
        @media (max-width:576px) {
            width: 98%;
        }
    }

    .chipsADD::-webkit-scrollbar {
        display: none;
    }

    .headersText {
        font-size: 16px;
        font-family: $Inter-bold;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: end;
        width: 100%;

        @media (max-width:576px) {
            font-size: 14px;
            padding-left: 2px;
        }

        .projectCount {
            font-size: 16px;
            font-family:$Inter-bold;

            @media (max-width:576px) {
                font-size: 16px;
            }
        }

        .projectCountZero {
            font-size: 22px;
            color: $Text-Color;
            font-family: $Roboto-light;

            @media (max-width:576px) {
                font-size: 16px;
            }
        }
    }


    .tableHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 55px;

        ::-webkit-scrollbar {
            background-color: #F2F3F4;
            width: 0px;
            height: 0px;
        }
        @media (max-width:576px) {
            height: 60px;
            width: 100%;
        }
    }

    .noFilterHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 55px;

        @media (max-width:576px) {
            height: 55px;
            width: 100%;
        }
    }

    .agreementheaderText {
        font-size: 22px;
        font-family: $Roboto-light;

        @media (max-width:576px) {
            font-size: 18px;
            padding-top: 30px;
        }

        .agreementProjectCount {
            font-size: 22px;
            font-family: $Roboto-medium;

            @media (max-width:576px) {
                font-size: 18px;
                padding-top: 30px;
            }
        }
    }

    .headerAllText {
        @media (max-width:576px) {

            padding-top: 40px;
        }
    }

    .headerText {
        font-family: $Roboto-light;

        @media (max-width:576px) {
            font-size: 18px;
            padding-top: 30px;
        }

        .projectCount {
            font-family: $Roboto-medium;

            @media (max-width:576px) {
                font-size: 18px;
                padding-top: 30px;
            }
        }
    }

    .filterApply {
        display: flex;
        color: $Text-Color;
        text-align: start;
        width: 98%;
        margin:0 auto;

        @media (max-width:576px) {
            flex-direction: column;
            padding-left: 20px;
            width: 92%;
        }

        .filterTextApply {
            height: 100px;
            display: flex;
            flex-direction: column;
            justify-content: space-around;


            @media (max-width:576px) {
                height: 90px;
                align-items: flex-start;
                flex-direction: column;
            }
        }

        .agreementsearchText {
            @media (max-width:576px) {
                width: 100%;
            }
        }



        .chipClass {
            height: 25px;
            margin-right: 4px;
            color: #FFFFFF;
            border-radius: 5px;
            font-family: $Inter-medium;
            background-color: #A5A5A5;
            border: 1px solid #6F6F6F;

            @media (max-width:576px) {
                margin-top: 0px;
            }

            .chipCross {
                font-size: 16px;
                color: #FFFFFF;
                font-family: $Inter-medium;
                cursor: pointer;
            }
        }
    }

    .cellWithImg {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        color:#101828;
        font-size: 14px;
        font-family: $Inter-medium;

        .MuiSvgIcon-root {
            font-size: 20px;

        }

        .contractNameText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

        }
    }
    .contractNameText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

    }
    .contractNameTextsponser{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
        text-align:start;
    }
    .addEllipsis {
        color: #475467;
        font-family: $Inter-regular;
    }

    .noFilter {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: calc(100vh - 181px);

        @media (max-width:576px) {
            height: calc(100vh - 210px);

        }
    }

    .filterApplyFilter {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        height: calc(100vh - 181px);

        @media (max-width:576px) {
            height: calc(100vh - 163px);

        }
    }

    .noData {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        padding-top: 10%;
        .ImgStyleNoFilter {
     
            @media (max-width:576px) {
            height: 50%;

            }
        }
    }

    .nofilrerHelperText {
        color: #3440546c;
        font-family: $Inter-medium;
        font-size: 14px;

        @media (max-width:576px) {
            font-size: 16px;
            padding: 0px 10px;
        }
    }
}