/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

//common background colour variables


$color_1: #979797;
$color_2: #000;
$color_3: #ffffff;
$color_4: #1D7874;

$background-color_1: #ffffff;
$background-color_2: aliceblue;
$background-color_3: #d9d9d9;
$background-color_4: #466cd3;
$background-color_5: #f2f2f2;
$btnBgColor:"#1976D2";
$border-color_1: grey;
$password-Format-color:#4772C5;
//form colour variables

$color_1_form: rgba(0, 0, 0, 0.85);
$background-color_1_form: white;
$background-color_2_form: #f3f3f3;
$background-color_3_form: #dee2e6;
$background-color_4_form: rgb(214, 214, 214);

$header-Height : 80px;


$brand-color:#0388C3;
$brand-color-hover:#4ec9ff42;
$sidebar-text-color:#0388C3;

//Font Family

@font-face {
    font-family: 'robotoMedium';
    src: local('robotoMedium'), url(../fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
    font-family: 'robotoRegular';
    src: local('robotoRegular'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'robotoLight';
    src: local('robotoLight'), url(../fonts/Roboto-Light.ttf) format('truetype');
}
@font-face {
    font-family: 'robotoBold';
    src: local('robotoBold'), url(../fonts/Roboto-Bold.ttf) format('truetype');
}


@font-face {
    font-family: 'interSemiBold';
    src: local('interSemiBold'), url(../fonts/Inter-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'interBold';
    src: local('interBold'), url(../fonts/Inter-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'interRegular';
    src: local('interRegular'), url(../fonts/Inter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'interMedium';
    src: local('interMedium'), url(../fonts/Inter-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'interLight';
    src: local('interLight'), url(../fonts/Inter-Light.ttf) format('truetype');
}

$Roboto-medium: robotoMedium;
$Roboto-light: robotoLight;
$Roboto-regular:robotoRegular;
$Roboto-bold:robotoBold;

$Inter-Semi-bold:interSemiBold;
$Inter-bold:interBold;
$Inter-regular:interRegular;
$Inter-medium: interMedium;
$Inter-light: interLight;
// $Background-Color-SideBar: #131730;
// $Background-Color-Table: #192443;
// $Background-Color-Button: #4772C5;
// $ScrollBar-Color:#969FB7;
// $Background-Color-ScrollBar :#354164;
// $TextFiled-Border-Color: #D5D8DC;
// $Text-Color:#FFFFFF;
// $SideBar-Panel:#212C4D;
// $Search-Icon-Color:#76808F;
// $popupBg-Color:#212C4D;
// $SideBar-Notch-Color:#263765;
// $Tooltip-BoxShodow:#07193482;

$Background-Color-SideBar: #fff;
$Background-Color-Table: #1976D205;
$Background-Color-Button: #1976D2;
$ScrollBar-Color:#D5D8DC;
$Background-Color-ScrollBar :#F2F3F4;
$TextFiled-Border-Color: #D5D8DC; //nochange
$Text-Color:#071934;
$SideBar-Panel:#fff;
$Search-Icon-Color:#76808F;//nochange
$popupBg-Color:#212C4D;//nochange
$SideBar-Notch-Color:#76808F;
$Tooltip-BoxShodow:#07193482;//nochange
$Loader-Background:rgba(141, 141, 141, 0.02);
$Agreement-Name-Color:#1976D2;
$Chip-Color:#76808F;
$Email-Info-Color:#76808F;
$Text-Color-White:#fff;