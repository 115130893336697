/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import'../../assets/styles/variable.scss';

.mapContainer{
    width: 100%;
    height: 100%;
    
    .mapPopup{
        box-shadow: 1px 1px 10px #01050a7d;
        border-radius: 5px;
        max-width: 400px !important;
        .projectNameText{
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            font-family:$Inter-regular;
            font-size: 13px;
            margin-top: 5px;
            .titleText{
                font-family:$Inter-Semi-bold;
                font-size: 14px;
            }
        }
        .mapboxgl-popup-close-button{
            font-size: 22px;
        }
        .mapboxgl-popup-tip{
            display: none;
        } 
        .mapboxgl-popup-content{
            padding: 5px 5px;
            box-shadow: none;
            border-radius: 5px;
        }
        .popupData{
            padding: 10px 10px 10px 10px;
        }
        .linkButton{
            width: 100px;
            cursor: pointer;
            justify-content: left;
            color: #1976d2;
            font-size: 14px;
            padding-top: 10px;
        }
        .linkButton:hover{
            background: none !important;
        }
    }
}


.mapboxgl-ctrl-bottom-left {
    display: none;
}

.map-popup {
    width: 500px !important;
}

.mapboxgl-popup-close-button {
    font-size: 26px;
}

.layer-filter {
    padding: 5px;
    position: relative;
    &:hover {
        .action-icon {
            display: block;
        }
    }
    .action-icon {
        display: none;
        position: absolute;
        top: -5px;
        right: 0;
        background: white;
        padding: 0 5px;
    }
}