.financial-dialogbox {
  .project-info-outer {    
    background-image: linear-gradient(to bottom right, #ffffff, #d5ded5);
    box-shadow: 0px 0px 4px 0px gray;
    border: 1px solid #f0f0f0;
    border-radius: 5px;
    max-width: 100%;
    margin: 10px 0px !important;

    .MuiAccordionSummary-content {
      margin: 10px 0 !important;
    }
    .project-info {
      display: flex;
      justify-content: space-between;
    }

    .info-box {
      display: flex;

      div:nth-child(odd) {
        white-space: pre;
        font-weight: 700;
      }

      div:nth-child(even) {
        padding: 0px 10px;
      }
    }

    .project-name {
      width: 45%;
    }

    .country {
      width: 15%;
    }

    .sector {
      width: 20%;
    }

    .sub-sector {
      width: 20%;
    }
  }

  .spread-sheet-container {
    border: 1px solid #a9a9a9;
    padding: 0px 1px 0 0;
    overflow: hidden;
  }

  .formula-bar {
    text-align: left;
    padding: 3px 8px;
    width: 100%;
  }

  .financial-loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  *:focus-visible {
    outline: none;
  }
}