/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

.pdfveiw {
    display: flex;
    justify-content: center;
    .webviwer {
        height: 100vh;
       width: calc(100vw - 200px);
       @media (max-width:576px) {
        height:100vh;

        width: 80vw;
      }
    }
}