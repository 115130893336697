/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import '../../assets/styles/global.scss';
@import '../../assets/styles/variable.scss';

.helpWrapperMain {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    height: calc(100% - 50px);

    @media (max-width:576px) {}

    .helpWrapper {
        text-align: left;
        height: 100%;
        width: 95%;
        margin: 0 auto;

        .title {
            display: grid;
            padding: 40px 0px 20px 0px !important;
            font-size: 26px;
            font-family: $Inter-bold !important;
            color: #000000;

            @media (max-width:576px) {
                font-size: 20px;
            }

            .titleDesc {
                font-size: 20px;

                @media (max-width:576px) {
                    font-size: 18px;
                }
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            font-family: $Inter-regular ;
            line-height: 20px;
            // height: calc(100% - 120px);
            overflow: auto;

            @media (max-width:576px) {

                height: calc(100% - 168px);

            }

            .typegraphyText {
                font-size: 16px;

                .orderList {

                    font-family: $Inter-medium ;
                    color:#000000;
                    .unorederList {
                        padding-top: 10px !important;
                        font-size: 14px !important;
                        padding-bottom: 10px !important;
                        color: #8A8A8A !important;
                        line-height: 25px;
                        font-family: $Inter-regular !important;
                    }
                }
                .boldText{
                    font-family: $Inter-medium;
                    color:#000000;
                }
            }

        }

        ::-webkit-scrollbar {
            background-color: $Background-Color-ScrollBar;
            width: 6px;
            height: 8px;
        }

        ::-webkit-scrollbar-track {
            background-color: $Background-Color-ScrollBar;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: $ScrollBar-Color;
            border-left: 0px solid $ScrollBar-Color;
            border-right: 0px solid $ScrollBar-Color;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: $ScrollBar-Color;
        }

    }

    .footerCopyRightText {
        .copyRightText {
            font-family: $Inter-regular;
            font-size: 12px;
            display: flex;
            height: 40px;
            align-items: center;
            padding-left: 36px;
        }
    }
}


.faq-pane{
    display: block;
    list-style-type: decimal;
    overflow-x: hidden;
    // height: calc(100% - 30px);
   
    .question{
        font-family: $Inter-medium;
        color:#000000;
    }

    .boldText{
        font-family: $Inter-medium;
        color:#000000;
    }

    ul.unorederList {
        padding-top: 10px !important;
        font-size: 14px !important;
        padding-bottom: 10px !important;
        color: #8A8A8A !important;
        line-height: 40px;
        font-family: $Inter-regular !important;
        list-style-type: disc;
        
    }
    .qa-answer {
        width: 92%;
    }
    .max-w-600 {
        max-width: 600px;
    }
    ul.bulletin{
        list-style-type: circle;
    }

    .footerCopyRightText {
        .copyRightText {
            font-family: $Inter-regular;
            font-size: 12px;
            background-color: white;
            width: 100%;
            position: fixed;
            height: 40px;
            text-align: left;
            left: 0;
            bottom: 0;
            padding: 10px;
        }
    }
    .sub-header{
        font-family: $Inter-bold;
        text-decoration: underline;
        font-size: 16px;
    }

    .kibana-image{
        font-style: italic ;
        img {
            width: 700px;
            border: 1px solid gray;
          }
    }

    .helpWrapper {
        text-align: left;
        height: 100%;
        padding: 0 40px;
        margin: 0 auto;

        ol {
            padding: 0;
        }

        .description {
            display: flex;
            flex-direction: column;
            font-family: $Inter-regular ;
            line-height: 20px;
            // height: calc(100% - 120px);
            overflow: auto;

            @media (max-width:576px) {

                height: calc(100% - 168px);

            }
        }
        .fixed-parent {
            height: calc(100vh - 100px);
        }
        .fixed-height {    
            @media screen and (min-height:400px) and (max-height: 600px) {
                max-height: 350px;
                }
            @media screen and (min-height:600px) and (max-height: 700px) {
                max-height: 450px;
                }
            @media screen and (min-height: 700px) and (max-height: 900px) {
                max-height: 550px;
                }
            @media screen and (min-height: 900px) and (max-height: 1000px) {
                max-height: 650px;
                }
            @media screen and (min-height: 1000px) and (max-height: 1200px) {
                max-height: 800px;
                }
            @media (min-height: 1200px) {
                max-height: 1000px;
                }
        }
        ::-webkit-scrollbar {
            background-color: $Background-Color-ScrollBar;
            width: 6px;
            height: 8px;
        }

        ::-webkit-scrollbar-track {
            background-color: $Background-Color-ScrollBar;
        }

        ::-webkit-scrollbar-thumb {
            border-radius: 4px;
            background: $ScrollBar-Color;
            border-left: 0px solid $ScrollBar-Color;
            border-right: 0px solid $ScrollBar-Color;
        }

        ::-webkit-scrollbar-thumb:hover {
            background: $ScrollBar-Color;
        }

    }
   
}