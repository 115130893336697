/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import'../../assets/styles/variable.scss';

.profilewrapper{
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #FFFF;
    .breacrumTitle{
        display: flex;
        align-items: center;
        padding-left: 15px;
        height: 50px;
       font-family: $Inter-medium;
        font-size: 20px;
        color: $Text-Color;
        .breacrumTitleIcon{
        color: $Text-Color;
        text-transform: capitalize;
        cursor: pointer;
      }
      .buttonBredcrum{
        padding: 0px;
      }
    }

    .formWrapper{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: auto;
        .formBox {
          border-radius: 4px;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }
          .formWrapper::-webkit-scrollbar {
            display: none;
          }
    .circuleBox{
      height: calc(100vh - 117px);
      display: flex;
      justify-content: center;
      align-items: center;
    }
        .formBox{
            
            width: 50%;
            height: calc(100vh - 117px);
            @media (max-width:576px) {
              width: 100%;
             }
         
            .innerBox{  
    
              padding: 8%;
              background-color: $Background-Color-SideBar;
              border-radius: 14px;
              @media (max-width:576px) {
                border: none;
               }

              .tileEdit{
                  display: flex;
                  font-family: $Inter-medium;
                  font-size: 22px;
                  padding-bottom: 15px;
                  color: $Text-Color;
              }
              .emailInfo{
                  font-family: $Inter-regular;
                  color:$Email-Info-Color;
                  display: flex;
                  text-align: start;
                  font-size: 12px;
              }
              .jobDescription{
                  padding-top: 20px;
                  padding-bottom: 15px;
                  display: flex;
                  .sectionTitle{
                      color: $Text-Color;
                      font-family: $Inter-medium;
                      font-size: 20px;
                  }
              }
            }
        }
        .signupBtn {
          font-family: $Inter-medium !important;
          font-size: 16px;
          width: 100%;
          background-color: $Background-Color-Button;
          color: $Text-Color-White;
        }
    
        .signupBtnDisabled {
          font-family: $Inter-medium !important;
          font-size: 16px;
          width: 100%;
          background-color: #80808087;
          color: #00000061;
      
        }
    
    }
    .formWrapper::-webkit-scrollbar {
      display: none;
    }

    .gridboxMargin{
      margin-bottom: 7px;
      .MuiTextField-root{
        .MuiInputLabel-root{
          font-family: $Inter-regular !important;
        }
        .MuiOutlinedInput-root{
         font-family: $Inter-regular !important;
        }
      }
      @media (max-width:576px) {
        margin-bottom: 0px;
       }
    }
}

.MuiMenu-list{
  .country {
    .country-name{
      margin-left: -20px;
    }
   svg {
      display: none;
    }
  }
}
.phoneNumberWrapper {
  .MuiIconButton-root {
    position: absolute !important;

  }
  svg {
    display: none !important;
  }

  }




.MuiAutocomplete-listbox {
  height: 250px;

}

.MuiAutocomplete-listbox::-webkit-scrollbar {
  width: 8px;
}
.MuiAutocomplete-listbox::-webkit-scrollbar-track {
  background-color: $Background-Color-ScrollBar;
}
.MuiAutocomplete-listbox::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: $ScrollBar-Color;
}


.MuiAutocomplete-listbox {
  .Mui-focused {
    background-color: $Background-Color-SideBar !important;
    color: $Text-Color;
  }
  .MuiAutocomplete-option[aria-selected="true"] {
    background-color: $Background-Color-SideBar !important;
    color: $Text-Color;
  }
}

.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  border-color: "#80808087";
}

#country-menu {
  .MuiPopover-paper {
    background-color: $SideBar-Panel !important;
    color: $ScrollBar-Color !important;
    .MuiMenuItem-root:hover {
      background-color: $Background-Color-SideBar !important;
      color: $Text-Color;
    }
    .Mui-selected {
      background-color: $Background-Color-SideBar !important;
      color:$Text-Color;
    }
  }

  .MuiPopover-paper::-webkit-scrollbar {
    width: 8px;
  }
  .MuiPopover-paper::-webkit-scrollbar-track {
    background-color: $Background-Color-ScrollBar;
  }
  .MuiPopover-paper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: $ScrollBar-Color;
  }
}