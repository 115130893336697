/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import "../../assets/styles/variable.scss";
@import "../../assets/styles/global.scss";

.menuTabsWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 3px;
  border-bottom: 1px solid #eaecf0;
  height: 38px;

  .tabsProject {
    padding: 0px 0px;
    height: 100%;
    @media (max-width:576px) {
      min-width: 70px;
     }
  }

  .tabsAgreement {
    height: 100%;
    @media (max-width:576px) {
      min-width: 70px;
     }
  }

  .menuTab {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-self: center;
    height: 100%;

    .namestab {
      font-size: 15px;
      height: 100%;
      padding-bottom: 7px;
      font-family: $Inter-bold;
      text-transform: none;
      @media (max-width:576px) {
        display: none;
        padding: 0px 0px;
      }
    }
    .userLogo{
      display: none;
      @media (max-width:576px) {
        display: block;
      }
    }
   
  }


  .MuiTabs-root {
    margin-left: 5px;
    min-height: 0px;
    height: 38px;
    text-transform: none;
    padding-left: unset !important;
    border-bottom: none !important;
  }

  .Mui-selected {
    color: #4a4a4a !important;
  }
  .MuiTabs-indicator{
    height: 2px;
    background-color: #2A90BD;
  }
}

.mainclassForSeachFitler {
  display: flex;
  width: 50%;
  align-items: center;
  justify-content: end;
  padding-top: 3px;
  @media (max-width:576px) {
    width: 80%;
   }
  .mainclassForSerach {
    width: 60%;
    margin-left: 16px;
    margin-right: 10px;
    @media (max-width:576px) {
      width: 100%;
     }
  }

  .fullTextSearchBox {
    width: 100%;
    input {
      padding: 4.5px 0px 4.5px 10px;
      font-size: 14px;
      font-family: $Inter-regular;
    }
    .searchUserIcon{
      color: #3440546c;
      padding-right: 5px;
    }
    .searchIconButtoon {
      padding: 0px;
    }
  }
}
