.search-bar-container {
  display: flex;
  align-items: center;
  border: 1px solid #cfcdcd;
  border-radius: 5px;
  padding: 2px 3px;

  .input-box {
    border: none;
    outline: none;
  }

  .search-icon {
    padding: 0px;
  }

}
.custom-date-range-picker {
  input {
    padding: 9px;
    font-size: 14px;
  }
}

#filters-select {
  padding: 0;
}