/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

.listMainWrapper {
  height: 30px;
  .itemButtons {
    padding: 0px 0px 0px 0px;

    .listItemText {
      padding-left: 3px;
      margin: 0px;
    }
  }
  .itemButtons:hover {
    height: 30px;
    align-items: center;
    display: flex;
  }
}
