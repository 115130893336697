/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import "../../assets/styles/variable.scss";
@import "../../assets/styles/global.scss";
.resetPopup{
.formWidth{
  width: 400px;
  @media (max-width:576px) {
    width:auto;

}
}

  
  .resetPasswordContainer {
    
    display: flex;
    color: $Text-Color;

    flex-direction: column;
     background-color:$Background-Color-SideBar;
     
    .resetPasswordWrapper {
  
     
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
  
      .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
       
      }
  
      .changePassWrapper {
        .MuiTextField-root{
          .MuiInputLabel-root{
            font-family: $Inter-regular !important;
          }
          .MuiOutlinedInput-root{
           font-family: $Inter-regular !important;
          }
        }

        border-radius: 8px;
        .newPasswordInput{
          position: relative;
        }        
        .timer-count {
          font-size: 12px;
        }
  
  
      }
  

    }

  
    .titleTextConfirm {
      font-family: $Inter-medium !important;
      font-size: 20px;
      color:$Text-Color;
    }
    .popupActionsWrapper{
      padding: 0 14px 16px !important;
    }
    
  }
}

.resetPasswordContainerLoading {
  display: flex;
  flex-direction: column;
  height: 440px;
  width: 400px;
.loadingApprove{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}
}


