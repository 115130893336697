/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import'../../assets/styles/variable.scss';

.accordion {
    box-shadow: none;
    border-radius: 0px;
    position: unset;
    height: 100%;
    margin: 0px 15px 0px 15px;

    ::-webkit-scrollbar {
        background-color: #F2F3F4;
        width: 6px;
    }

    ::-webkit-scrollbar-track {
        background-color: #EDF2F4;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: #D5D8DC;
        border-left: 1px solid #EDF2F4;
        border-right: 1px solid #EDF2F4;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: #9d9ea0;
    }
    .accorionMain{
        height: 100%;
        overflow: hidden;
        white-space: nowrap;
    }
    .sidebuttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .searchButtonToggle{
            padding: 4px;
        }
        .searchApply{
            color:#0E0E0E;
        }
        .notSearchApply{
            color:#AAAAAA;
        }
        .titleOfSidebar {
            font-family: $Inter-Semi-bold;
            font-size: 14px;
        }
    }
    .accordionDetailsWithSearch{
        height: calc(100% - 42px);
    }
    .accordionDetails { 
        height: calc(100% - 30px);
    }
    .serachBox {
        padding: 0px 2px;
        display: flex;
        align-items: center;
        width: 97%;
        margin: 6px 0px 8px 0px ;
        box-shadow: none;
        border-radius: 4px;
        color: #344054;
        border: 1px solid #D0D5DD;

        @media (max-width:576px) {
            width: 97%;
        }
        .MuiInputBase-input {
            padding: 0px 10px 0px 0px;
            font-size: 14px;
            margin-left: 2px;
        }
    }

    .searchUserIcon {
        color: #3440546c;
        font-size: 20px;
    }
    .listItemsWithSearch {
        width: 100%;
        max-width: 360px;
        position: relative;
        overflow: auto;
        white-space: nowrap;
        height: calc(100% - 33px);
        overflow-x: hidden;
    }
    .listItems {
        width: 100%;
        max-width: 360px;
        position: relative;
        overflow: auto;
        white-space: nowrap;
        height: 100%;
        overflow-x: hidden;
    }
    .nodataFound{
        font-family: $Roboto-light;
        font-size: 14px;
    }

    .loadingApprove {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    li {
        div:hover {
            height: 30px;
            align-items: center;
            display: flex;

        }

        span {
            font-size: 13px;
            overflow: hidden;
            text-align: left;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-family: $Inter-medium;
        }
    }

    .selectedCounts {
        display: flex;
        justify-content: space-between;
        box-shadow: 0 -1px 0px #07193426;

        .allcountdisplay {
            display: flex;
            height: 35px;
            align-items: center;
            color: #9BA3AD;
            font-size: 14px;
            font-family: $Roboto-light;
        }
    }

    .MuiInputBase-root{
      width: 90%;
    }
}

.list-container {
    border: 2px solid #bababa;
    border-radius: 10px;
  }
  
  .list-container .cdk-virtual-scroll-content-wrapper {
    display: flex;
    flex-direction: column;
  }
  
