.search-bar-container {
  display: flex;
  align-items: center;
  border: 1px solid #cfcdcd;
  border-radius: 5px;
  padding: 2px 3px;

  .input-box {
    border: none;
    outline: none;
  }

  .search-icon {
    padding: 0px;
  }

}

.aggregate-card {
  box-shadow: 0px 2px 1px -1px #1976d2, 0px 1px 1px 0px #1976d2, 0px 1px 3px 0px #1976d2 !important;
}