/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import '../../assets/styles/variable.scss';
@import '../../assets/styles/global.scss';

.Detailsdatatable {
    width: 100%;
    height:calc(100vh - 66px);
    background-color:#ffff;

    ::-webkit-scrollbar {
        background-color:$Background-Color-ScrollBar;
        width: 0px;
    }

    ::-webkit-scrollbar-track {
        background-color:$Background-Color-ScrollBar;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: $ScrollBar-Color;
        border-left: 1px solid $ScrollBar-Color;
        border-right: 1px solid $ScrollBar-Color;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $ScrollBar-Color;
    }
    .dataTableDetails{
        padding-top: 30px;
        height: calc(100vh - 305px);
        @media (max-width:576px) {
            padding-top: 0px;
            height: calc(100vh - 305px);
        }

    }

    .viewButton {
        padding: 2px 5px;
        border-radius: 5px;
        color: darkblue;
        border: 1px solid rgba(0, 0, 139, 0.596);
        cursor: pointer;
    }
    .cellAction{
        color: $Text-Color;
        font-family: $Roboto-medium;
        text-decoration: underline;
        font-size: 14px;
    }
    .tabaleloader{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;    
        width: 100%;
        height: 100vh;
        position: absolute;
        background-color: #b6bfd572;
    }
    .tableHeader {
        display: flex;
        flex-direction: column;

        @media (max-width:576px) {

            flex-direction: column;
        }

        .breadcrum {
            display: flex;
            align-items: center;
            height: 36px;
            padding: 4px 20px;
            @media (max-width:576px) {
                padding: 0px 20px;
                height: 30px;
            }
            .returnButtonText{
                font-family: $Inter-Semi-bold;
            }
            .returnButton{
                color: #5E5E5E ;
                text-transform: none;
                display: flex;
                padding: 1px 10px ;
                font-size: 12px;
                background: #FFFFFF;
                border: 1px solid #DEDEDE;
                border-radius: 6px;
                .arrowClass{
                    font-size: 12px;
                }
            }
        }
        .arrowstyle {
            cursor: pointer;
            color: $Text-Color;
            font-size: 45px;
        }

        .projectDetailsHeader {
 
            background-color: #FBFBFB;
            border: 1px solid #F2F4F7;
            display: flex;      
            align-items: center;
            padding:0px 30px 20px 20px;
            text-align: left;
            height:81px;
            @media (max-width:576px) {
                display: flex;
                flex-direction: column;
                width: 94%;
                padding: 5px 10px;
                height: 122px;       
            }
            // padding: 5px 0px 5px 30px;
            .agreementNameStyle{
                width: 36%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-right: 2%;
                @media (max-width:576px) {
                  padding-right: 0px;
                  width: 100%;
                  padding-bottom: 5px;
                }
        
                .agreementName {
                    font-family: $Inter-bold;
                    font-size: 18px;
                    color:#2D2D2D;
                    width: 100%;
                    text-align: start;
                    @media (max-width:576px) {
                        font-size: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                       
                    }
            
                }
            }
            .countryStyle {
                width: 13% ;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                padding-top: 12px;
                padding-right: 2%;
                @media (max-width:576px) {
                    width: 100%;
                    padding-top: 2px;
                     
                  }

            }

            .countryPadding {
                display: flex;

                .countryNameStyle {
                    font-family: $Inter-regular;
                    color:#787878;
                    font-size: 12px;
                }
            }

            .countryTextAlign {
                font-size: 18px;
                font-family: $Inter-bold;
                color: #000000;
                @media (max-width:576px) {
                    font-size: 12px;
                     
                  }
            }

            .sectorStyle {
                width: 20% ;
                padding-top: 12px;
                padding-right: 2%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                @media (max-width:576px) {
                    width: 100%;
                    font-size: 12px;
                    padding-top: 2px;

                  }
            }

            .subSectorStyle {
               width: 25%;
               padding-top: 12px;
               overflow: hidden;
               white-space: nowrap;
               text-overflow: ellipsis;
                 @media (max-width:576px) {
                  width: 100%;
                  font-size: 12px;
                  padding-top: 2px;

                }

        
            }

        }

        .headerText {
            font-size: 22px;
            font-family: $Roboto-regular;

            .projectCount {
                font-size: 22px;
                font-family: $Roboto-medium;
            }
        }

        .agrementCounts {
            padding-left: 20px;
            display: flex;
            align-items: end;
            height: 50px;
            padding-bottom: 10px;

            .headersText {
                font-size: 22px;
                font-family: $Roboto-light;
                text-align: start;
                color:$Text-Color;
                .projectCount {
                    font-size: 22px;
                    font-family: $Roboto-medium;
                }
            }
        }

    }

    .status {
        width: 100%;
    }

    .gridTable {
        border: none;
        height: 100%;
        width: 100%;

        @media (max-width:576px) {
            height: calc(100vh - 261px);
        }

    }
    .addEllipsis{
        color: #475467;
        font-family: $Inter-regular;
        .contractNameTextsponser {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
        }
    }

    .cellWithImg {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        color:#101828;
        font-size: 14px;
        font-family: $Inter-medium;

        .MuiSvgIcon-root {
            font-size: 20px;


        }

        .contractNameText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }


}