/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import '../../assets/styles/variable.scss';

// .dialogbox{
//   @mixin paddingBox {
//     display: flex;
//     padding: 0px 0px 0px 40px;
//     background-color: $Background-Color-SideBar;
//   }
//   @mixin toolbarpdf {
//     display: flex;
//     align-items: start;
//     padding: 0px ;
//     padding-left: 10px;
//     background-color:$Background-Color-SideBar ;

//     @media (max-width:576px) {
//       width: 90%;
//     }
//     .pdfNameDiv {
//       display: flex;
//       background-color:$Background-Color-SideBar;
//       align-items: center;
//     }
//   }
//   .showPDFviewr {
//     display: flex;
//     justify-content: center;
//     @media (max-width:576px) {
//       display: block;
//     }
//     .paddingBox {
//       @include paddingBox();
//     }
//     .toolbarpdf {
//       @include toolbarpdf();
//     }
//   }
  .showPDFviewrFullPage {
    display: block;
    background-color: $Background-Color-SideBar;
    .paddingBox .pdfbox .pdfveiw .webviwer {
      width: 100vw;
    }
    .toolbarpdf {
      display: none;
    }
  }
//  div{
//    div{
//     // background-color:rgba(0, 0, 0, 0.2);
//    }
//   }
// .loadingApprove{
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
// }
//   .arrowstyle{
//     font-size: 30px;
//     color: $Text-Color;
//   }
//   .pdfbox{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     width: 100%;
//   }
//   .NotPDF{
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     height: 100vh;
//     width: calc(100vw - 200px);
//     @media (max-width:576px) {
//      height:100vh;

//      width: 80vw;
//    }
//    .errorSymbol{
//      font-size: 60px;
//    }
//    .notPdfText{
//      font-size: 25px;
//    }
//   }
//   .titlesPDF{
//     display: flex;
//     flex-direction: column;

//     .divider{
//       color: white;
//       background-color: white;
//     }
//     .contractNameText{
//       padding-right: 5px;
//       font-size: 18px;
//       font-family: $Roboto-light;
//       @media (max-width:576px) {
//         font-size: 14px;
//       }

//     }
//     .agreementNameText{
//       padding-left: 5px;
//       font-size: 18px;
//       font-family: $Roboto-light;
//       @media (max-width:576px) {
//         font-size: 14px;
//       }
//     }
//   }
// }



