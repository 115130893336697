/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/



.MuiAutocomplete-listbox{
   text-align: left;
   text-overflow: ellipsis;

}	