/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/


iframe {
    border-style: none;
}

.iframeDiv{
    width: 100%;
    height: 100%;

    body .map-view-infraclear {
        height: 100%;
        width: 100%;
    }
    
    html, body {
        height: 100%
    }
    .dashboardTopNav{
        top:0 !important
    }
    body {
        display: flex;
        align-items: stretch;
        .uniSearchBar{
            display:none;
          } 
    }

}