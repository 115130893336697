/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import "../../assets/styles/variable.scss";


.logoutPopup {
    .dialogTitleStyle{
        display: flex;
        justify-content: space-between;
        padding: 10px 24px;
        .closeIcon{
            cursor: pointer;
            color: $Text-Color;
        }
    }
    .divider{
        color:$Background-Color-ScrollBar;
        background-color: $Background-Color-ScrollBar;
    }
    div{
        div{
         border-radius: 8px;
            background-color:$SideBar-Panel;
        }
    }
}

.titleText {
    font-family: $Inter-medium ;
    font-size: 20px;
    color: $Text-Color;
}

.titleDesc {
    font-family: $Inter-light;
    color: $Text-Color;
    line-height: 2.5;
    font-size: 15px;

}
.confirmationCancel{
    font-family: $Inter-medium !important;
    text-transform: capitalize !important;
    font-size: 15px;
    width: 160px;
    margin-right: 10px !important;
    box-shadow: 0px 2px 5px #0719341A;
}

.confirmation {
    font-family: $Inter-medium !important;
    text-transform: capitalize !important;
    font-size: 16px;
    width: 160px;
}