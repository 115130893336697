/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/


@import './variable.scss';
.center-align{
    display:flex;
    align-items:center;
    justify-content:center;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}


.flex-direction-column{
    display: flex;
    flex-direction: column;
}

.vertical-align{
    display: flex;
    align-items: center;
}

.popupActions {
    width: 100%;
    display: flex;
    flex-direction: row;  
    justify-content: space-between;
    align-items: center;
    .popupActionsWrapper{
      width: 100%;
      padding: 0 8px 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .secondaryBtn {
      background-color: $btnBgColor;
      border: 1px solid #1976D2;
      width: 45%;
      height: 40px;
      color: #1976D2;
     // font-family: $Roboto-medium;
      font-family: $Inter-medium;
      font-size: 14px;
      box-shadow: 0px 2px 5px #07193433;
      border: 1px solid #1976D2;
      border-radius: 5px;
  
    }
  
    .primeryBtn {
      background-color: #1976D2;
      border: 1px solid #1976D2;
      width: 45%;
      color: white;
      height: 40px;
      box-shadow: 0px 2px 5px #0B193233;
      border-radius: 5px;
      opacity: 1;
     // font-family: $Roboto-medium;
     font-family: $Inter-medium;
      font-size: 14px;
      &:hover {
        background-color: #1976D2;
      }
    }
  
    .loader {
   
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      width: 45%;
      height: 40px;
    }
  }
  .addEllipsis{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    // color: #1976d2;
    // font-size: 15px;
    // font-family: $Roboto-regular;
    .contractNameText {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        // padding-left: 20px;
    }
}

.scrollbarColor{
  ::-webkit-scrollbar {
    background-color:$Background-Color-ScrollBar;
    width: 8px;
    height: 8px;
}

::-webkit-scrollbar-track {
    background-color:$Background-Color-ScrollBar;
}

::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: $ScrollBar-Color;
    border-left: 0px solid $ScrollBar-Color;
    border-right: 0px solid $ScrollBar-Color;
}

::-webkit-scrollbar-thumb:hover {
    background: $ScrollBar-Color;
}
}