/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import'../../assets/styles/variable.scss';

.sideBarwrappers {
    display: flex;

    .toogleButton {
        display: flex;
        background-color: $Background-Color-SideBar;
        border-right:  1px solid #E0E0E0;
        position: relative;
        width: 15px;
        
        @media (max-width:576px) {
            cursor: pointer;
            position: unset;
        }

        .arrowButton {
            display: flex;
            color: #76808F;
            background-color:#fff;
            height: 30px;
            font-size: 20px;
            top: 0;
            left: -7px;
            z-index: 999;
            position: absolute;
            border-radius: 15px 0px 0px 15px;
            box-shadow: -3px 1px 2px 0px #d5d8dc;
            &:hover {
                background-color: #1976D2;
                color: #fff;
              }
            display: none;
        @media (max-width:576px) {
            display: block;
            right: 0;
            left: auto;
        }
        }

    }

    .closeToggle {
        display: flex;
        background-color: $Background-Color-SideBar;
        height: calc(100vh - 180px);
        position: relative;
        width: 15px;
        cursor: pointer;

        @media (max-width:576px) {
            width: 0px;
        }

        .leftArrowButton {
            display: flex;
            color: #76808F;
            background-color:#fff;
            height: 30px;
            font-size: 20px;
            box-shadow: 3px 1px 2px 0px #d5d8dc;
            border-radius: 0px 15px 15px 0px;
            &:hover {
                background-color: #1976D2;
                color: #fff;
              }
        }
    }

    .sidebar {
        min-height: 0px;
        width: 250px;
        background-color: $Background-Color-SideBar;
        box-shadow: 3px 0px 30px #0719340D;

        @media (max-width:576px) {
            box-shadow: none;
            background: white;
            width: 100%;
            position: absolute;
            z-index: 4;
            -webkit-transition: all 1s ease-out;

        }


        .arrowButtonDiv {
            display: none;

            @media (max-width:576px) {
                display: none;
                background-color: $Background-Color-Table;

                .arrowButton {
                    display: flex;
                    background-color: #FFFFFF;
                    height: 30px;
                    border: 1px solid #D5D8DC;

                    border-radius: 0px 15px 15px 0px;
                }

            }

        }

        .ArrowButtonDivs {
            background-color: $Background-Color-SideBar;
            display: none;

            @media (max-width:576px) {
                display: none;
                display: flex;
                justify-content: end;

                .arrowButton {
                    display: flex;
                    background-color: #FFFFFF;
                    height: 30px;
                    border: 1px solid #D5D8DC;

                    border-radius: 15px 0px 0px 15px;
                }

            }
        }


        .sidebarWrapper {
            background-color: $Background-Color-SideBar;
            width: 250px;

            @media (max-width:576px) {
                display: block;
                width: 100%;
            }
        }

        .siderButton {
            @media (max-width:576px) {
                display: none;
            }
        }

        .filterTop {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 17px 0px 14px 15px;
            @media (max-width:576px) {
                background-color: $Background-Color-SideBar;
                padding: 17px 20px 14px 15px;
            }

            .filterText {
                display: flex;
                font-family: $Inter-Semi-bold;
                font-size: 17px;

                @media (max-width:576px) {
                    font-size: 16px;

                }
            }

            .clearText {
                display: flex;
                font-family: $Inter-Semi-bold;
                color: #2A90BD;
                text-decoration: underline;
                text-transform: none;
                font-size: 14px;
                cursor: pointer;
            }
        }


        .sidebarFilter::-webkit-scrollbar {
            display: none;
        }

        .sidebarFilter {
            height: calc(100vh - 161px);
            -ms-overflow-style: none;
            scrollbar-width: none;
            background-color: $Background-Color-SideBar;

            @media (max-width:576px) {
                background-color: $Background-Color-SideBar;
            }

            .sidebarFilterMain{
                height: 100%;
            }
            .accordion {
                background-color: $Background-Color-SideBar;
                color: $Text-Color;
                box-shadow: none;
                border-radius: 0px;
                position: unset;
                margin: 0px 0px 0px 15px;
                height: 33%;
                @media (max-width:576px) {
                    margin: 0px 15px 0px 15px;
                }

            }

        }


    }
}