/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import "../../assets/styles/variable.scss";
@import "../../assets/styles/global.scss";

.subscribeWrapper {
  margin: 10px 30px;
  @media (max-width:576px) {
    margin: 10px 15px;
}
  .subscribeTitle {
    font-family: $Inter-Semi-bold;
    font-size: 22px;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0px 15px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .closeButton {
      padding: 2px;
    }
  }
  .userDetailLoading {
    height:calc(100vh - 151px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .actionDialog {
    padding: 8px 0px;
    .submitFormPopup {
      width: 100%;
      display: flex;
      align-items: center;

      .submitFormText {
        font-size: 16px;
        font-family: $Inter-medium;
        padding-left: 10px;
      }
    }
  }

  .formTextFields {
    width: 60%;
    @media (max-width:576px) {
      width: 95%;
  }
  }

  .subscribeContent {
    overflow: overlay;
    height: calc(100vh - 192px);

    .MuiMenu-list {
      .country {
        .country-name {
          margin-left: -20px;
        }
        svg {
          display: none;
        }
      }
    }
    .phoneNumberWrapper {
      .MuiIconButton-root {
        position: absolute !important;
      }
      svg {
        display: none !important;
      }
    }
    .subscribing-role {
      font-size: 14px;
    }
    .items {
      gap: 30px;
    }
    .items, .item {
      display: flex;
      align-items: center;
    }
    .item input {
      margin: 0 5px 0 0;
      cursor: pointer;
    }
    .MuiMenu-list {
      .country {
        .country-name {
          margin-left: -20px;
        }
        svg {
          display: none;
        }
      }
    }

    .contentText {
      padding: 15px 0px;
      font-family: $Inter-regular;
      font-size: 14px;
      line-height: 1.8;
      .topTextBold {
        font-family: $Inter-medium;
        font-size: 14px;
      }
    }
  }
  .MuiInputBase-input {
    padding: 6.5px 14px;
  }
  .MuiTextField-root {
    .MuiInputLabel-root {
      font-family: $Inter-regular !important;
    }
    .MuiOutlinedInput-root {
      font-family: $Inter-regular !important;
      border-radius: 8px;
      padding-left: 0px;
    }
    .MuiFormHelperText-root {
      font-family: $Inter-regular !important;
    }
  }

  .cancelButton {
    background-color: #c5c5c5;
    text-transform: none;
    padding: 5px 30px;
  }
  .cancelButton:hover {
    background-color: #c5c5c5;
  }
  .submitButton {
    text-transform: none;
    padding: 5px 30px;
    .submitFormLoading {
      padding: 3px 15px;
      display: flex;
    }
  }

}
