/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import "../../assets/styles/global.scss";
@import "../../assets/styles/variable.scss";
@import '../../assets/styles/global.scss';
@import '../../assets/styles/variable.scss';

.PasswordMessageWrapper {
  display: flex;
  justify-content: center;
}


div.callout {
  max-width: 294px;
  background-color: #707070;
  background-image: -moz-linear-gradient(top, #707070, #707070);
  z-index: 2;
  position: absolute;
  color: #ccc;
  padding: 0 10px;
  border-radius: 8px;
  box-shadow: 0 0px 40px 0px $Tooltip-BoxShodow;
  top: -192px;
  min-height: 70px;
}

.callout::before {
  content: "";
  width: 0px;
  height: 0px;
  border: 0.8em solid transparent;
  position: absolute;
}

.callout.bottom::before {
  left: 43%;
  bottom: -28px;
  border-top: 15px solid #707070;
}

.passwordMsg {
  padding: 20px 10px 20px 10px !important;
  display: flex;
  flex-direction: column;
  .passSubTitleHeader {
    padding-left: 10px;
    padding-bottom: 5px;
  }
  .passSubTitle {
    .messageText {
      padding: 5px 0 5px 7px;
    }
    .passwordImg {
      height: 16px;
      width: 16px;
    }
    color: #ffff !important;

    font-size: 13px !important;
    font-family: $Roboto-light !important;
    display: flex;
    align-items: center;
    .wrongIcon {
      font-size: 12px;
      background-color: #c33e38;
      color: #fff;
    }
    .correctIcon {
      font-size: 12px;
      background-color: #7cb741;

      color: #fff;
    }
  }
}
