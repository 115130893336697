/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import '../../assets/styles/variable.scss';

.datatable {
    width: 100%;
    height: 100%;

    ::-webkit-scrollbar {
        background-color:$Background-Color-ScrollBar;
        width: 0px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background-color:$Background-Color-ScrollBar;
    }

    ::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background: $ScrollBar-Color;
        border-left: 0px solid $ScrollBar-Color;
        border-right: 0px solid $ScrollBar-Color;
    }

    ::-webkit-scrollbar-thumb:hover {
        background: $ScrollBar-Color;
    }

    .viewButton {
        padding: 2px 5px;
        color: #1976D2;
        font-family:$Roboto-medium;
        font-size: 14px;
        text-decoration: underline ;
        cursor: pointer;
    }

    .tableHeaderName {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 59px;
        border: 1px solid #EAECF0;
        border-radius: 3px 0px;
        width:98%;
        margin:0 auto;

        @media (max-width:576px) {
            height: 30px;
            padding: 0 10px;
            width:92%;

        }

        .tableHead{
            font-family:$Inter-Semi-bold;
            font-size: 18px;
            padding-left: 13px;
        }
        .headerText {
            font-size: 22px;
            font-family: $Roboto-light;

            .projectCount {
                font-size: 22px;
                font-family: $Roboto-medium;
            }
        }




    }

    .status {
        width: 100%;
    }

    .gridTable {
        border: none;
        width: 100%;

        @media (max-width:576px) {      
            height: calc(100vh - 205px);
        }

        .MuiDataGrid-footerContainer {

            background-color: #F9F9F9;
    
        }
    }

    .cellWithImg {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        font-size: 15px;
        font-family: $Roboto-regular;

        .MuiSvgIcon-root {
            font-size: 20px;

        }

        .contractNameText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            padding-left: 5px;
        }
    }



    .footerCopyRightText{
        padding-left: 13px;
        font-family: $Inter-regular;
        font-size: 12px;
        
        @media (max-width:576px) {
            display: none;
        }
    }
    .MuiDataGrid-cell:focus {
        outline: none !important
    }


    .MuiDataGrid-root {
        font-size: 14px;
        font-family: $Roboto-light;
    }

    .MuiDataGrid-columnHeader:focus {
        outline: none !important;
    }


    .MuiDataGrid-columnHeaders {
        padding: 0 3px;
        height: 42px;
        min-height: 0px !important;

        .MuiDataGrid-columnHeaderTitle {
            font-family: $Inter-medium;
            color:#475467;
            font-size: 12px;
        }
    }

    .MuiTablePagination-selectLabel{
        color:#475467;
        font-family: $Inter-Semi-bold;
    }
    .MuiTablePagination-select{
        color:#475467;
        font-family: $Inter-Semi-bold;
    }
    .MuiTablePagination-displayedRows{
        color:#475467;
        font-family: $Inter-Semi-bold;
    }
    .MuiTablePagination-actions{
        color:#475467;
        font-family: $Inter-Semi-bold; 
    }

}

.MuiDataGrid-main {
    border-top: 1px solid #EAECF0;
}

.common-cell-renderer {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .filter {
        background-color: #eaecf0;
        padding: 5px;
        width: fit-content;
        display: none;
        position: absolute;
        top: 0;
        right: -10px;
        gap: 5px;
        height: 100%;
        > * {
            font-size: 15px;
            color: #1976D2;
        }
        div {
            display: flex;
            gap: 2px;
            svg {
                margin-top: 3px;
                width: 16px;
                height: 16px;
            }
        }
    }
    &:hover .filter {
        display: flex;
        align-items: center;
    }
}