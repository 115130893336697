/**
Copyright (C) 2019-2022, Infraclear Inc. All Rights Reserved.
*/

@import '../../assets/styles/variable.scss';

.navbar {
  display: flex;
  align-items: center;
  padding: 0px 15px 0px 15px;
  height: 50px;
  background-color: $Background-Color-SideBar;
  border-bottom: 1px solid #EAECF0;
  @media (max-width:576px) {
    padding: 0px 5px 0px 5px;
   }
  .wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .navBarRight {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      @media (max-width:576px) {
        padding: 0px 1px;
        width:60%;
       }

    }
    .tabsProject {
      padding: 0px 0px;
      height: 100%;
      @media (max-width:576px) {
        min-width: 40px;
       }
    }
  
    .tabsAgreement {
      padding: 0px 0px;
      height: 100%;
      @media (max-width:576px) {
        min-width: 40px;
       }
    }


    .menuTab {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-self: center;
      height: 100%;

      .namestab {
        font-size: 15px;
        height: 100%;
        padding: 0px 20px;
        padding-top: 5px;
        font-family: $Inter-bold;
        text-transform: none;
        color:#000000;
        @media (max-width:576px) {
          display: none;
          padding: 0px 0px;
        }
      }
      .userLogo{
        display: none;
        @media (max-width:576px) {
          display: block;
        }
      }
     
    }
  
    .MuiTabs-root {
      min-height: 0px;
      height: 100%;
      text-transform: none;
      padding-left: unset !important;
      border-bottom: none !important;
    }
    .Mui-selected .namestab {
      color: #1976d2 !important;
    }

    .items {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
    
    }
  }

  .logo-wrapper {
    display: flex;
    align-items: center;
  }

  .logo {
    @media (max-width:576px) {
      padding-right: 0px;
    }
    .imglogo {
      width: 160px;
      display: flex;
      cursor: pointer;

      @media (max-width:576px) {
        width: 120px;
      }
    }
  }

  .menuItemValue {
    font-family: $Roboto-regular;
    font-size: 14px;
  }

  .profile {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    .trialWapper{

      .trialTextIconMain{
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        
        @media (max-width:576px) {
          flex-direction: column;
          margin-right: 0px;
        }
      }
      .subscribe{
        background-color: #007D3A !important;
        border-radius: 5px !important;
        cursor: pointer !important;
        @media (max-width:576px) {
         margin-top:10px;
        }
      }
      .trialDaysGreen {
        background-color: #FF7F00;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px 10px !important;
        font-size: 12px;
        cursor: auto;
        color: #FFFF;
         margin-left: 10px;
        font-family: $Inter-regular;
        
       
        .trialDays {
          margin-top: 1px;
          text-transform: none !important;
        }
        .subscribeUser {
          margin-top: 1px;
          text-transform: none !important;
          font-family: $Inter-Semi-bold;
        }
        @media (max-width:576px) {
          font-size: 8px;
          padding: 0px 5px;
          min-width: 10px;
        }
        
      }
      .trailDaysRed {
        background-color: #CB3B0E;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 10px;
        font-size: 12px;
        cursor: pointer;
        color: #FFFF;
        font-family: $Inter-regular;
        .trialDays {
          margin-top: 2px;
          text-transform: none;
        }
        @media (max-width:576px) {
          font-size: 10px;
          padding: 0px 5px;
          .trialDays{
            margin-top: 0px !important;
          }
          
        }
        .trialDays {
          margin-top: 2px;
          text-transform: none;
        }
      }
    }

    .leftBorderAdd{
      border-left: 2px solid #D5D8DC;
    }
    .profileWrapper {
      display: flex;
      flex-direction: row;
      align-items: center;

      .helpTextIconMain {
        .helpTextIcon {
          color: #363636;
          border-radius: 15px;
        }
      }
    }

    .avatarStyle {
      font-size: 16px;

      @media (max-width:576px) {
        font-size: 12px;
        width: 30px;
        height: 30px;
      }
    }


    .profilename {
      cursor: pointer;
      font-family: $Inter-Semi-bold;
      padding-left: 10px;
      padding-right: 4px;
      font-size: 14px;
      @media (max-width:576px) {
        padding-left: 0px;
      }
    .avatarProfileName{
      display: none;
      @media (max-width:576px) {
        display: flex;
        font-size: 12px;
        width: 25px;
        height: 25px;
        margin-left: 5px;
      }
    }
    .textProfileName{
      @media (max-width:576px) {
        display: none;
      }
    }
    }

    .menulist {
      font-size: 14px !important;
      color: $Background-Color-Button;
      top: 44px !important;
      font-family: $Roboto-regular !important;
      box-shadow: 0px 3px 20px #0719341A;

    }
  }


}

.itemMenuList {
  font-size: 14px !important;
  font-family: $Inter-regular ;

  @media (max-width:576px) {
    font-size: 12px;
  }
}

.MuiMenu-list {
  color: $Text-Color;
  border: 1px solid $Background-Color-ScrollBar;
}
